import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import './Submissions.css'; // Import the CSS file

const Submissions = () => {
  const [randomSubmission, setRandomSubmission] = useState(null);

  useEffect(() => {
    const fetchRandomSubmission = async () => {
      const submissionsSnapshot = await getDocs(collection(db, 'submissions'));
      const submissionsData = submissionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (submissionsData.length > 0) {
        const randomIndex = Math.floor(Math.random() * submissionsData.length);
        setRandomSubmission(submissionsData[randomIndex]);
      }
    };

    fetchRandomSubmission();
  }, []);

  return (
    <div className="submission-container">
      {randomSubmission ? (
        <div className="submission-card">
          <h1 className="submission-title">
            Before you vote for these guys, did you know that...
          </h1>
          <img
            src={randomSubmission.image}
            alt={randomSubmission.alt || 'Submission Image'}
            className="submission-image"
          />
          <p className="submission-headline">{randomSubmission.headline}</p>
          {randomSubmission.url && (
            <a
              href={randomSubmission.url}
              target="_blank"
              rel="noopener noreferrer"
              className="submission-link"
            >
              Link
            </a>
          )}
          <p className="submission-footer">
            Not affiliated with Harris Walz campaign
          </p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Submissions;
