import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Submissions from './components/Submissions';
import './App.css';
import data from './data.json';
import config from './config.json';

const App = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

const AppContent = () => {
  const [selectedPair, setSelectedPair] = useState(null);

  useEffect(() => {
    if (!config.comingSoon) {
      const randomPair = data[Math.floor(Math.random() * data.length)];
      setSelectedPair(randomPair);
      const timer = setTimeout(() => {
        window.location.href = config.redirectURL;
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, []);

  if (config.comingSoon) {
    return <h1 className="">Coming Soon</h1>;
  }

  if (!selectedPair) {
    return null;
  }

  return (
    <div className="">
      <Routes>
        <Route path="/" element={<Submissions />} />
      </Routes>
    </div>
  );
};

export default App;
