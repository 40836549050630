// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAmLs0Wmoig3m85EumZzLfNVYh8fzYEQlE",
    authDomain: "jdvt-blog.firebaseapp.com",
    projectId: "jdvt-blog",
    storageBucket: "jdvt-blog.appspot.com",
    messagingSenderId: "299281646696",
    appId: "1:299281646696:web:77a7a5f0bb1ab3ec7227dd",
    measurementId: "G-GDPZP3BVC8"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
export const auth = getAuth(app);
export const storage = getStorage(app);